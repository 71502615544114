<template>
    <div id="home-contact-us" class="d-flex-ac-jc">
        <div class="contact-us d-flex-r-w-ac-jsb-gap15">
            <h3 class="grow-1 basis-400">ارتباط با زرکوین</h3>
            <div class="items grow-4 basis-400 d-flex-r-w-jsa-ac-gap15">
                <a class="item d-flex-c-ac-jfs-gap10" v-for="item in items" :key="item.name" @click="$push(item.route)">
                    <img :src="require('@/assets/Icons/'+item.icon)"/>
                    <b class="gap-nb">{{item.name}}</b>
                    <p>{{item.desc}}</p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HomeContactUs",
        data(){
            return{
                items:[
                    {
                        name : 'پشتیبانی آنلاین',
                        desc : 'سوالات خود را از ما بپرسید',
                        icon : 'online-chat.svg',
                        route : {name : 'Home'}
                    },
                    {
                        name : 'بلاگ ما',
                        desc : 'مطالب آموزشی رمزارزها',
                        icon : 'blog.svg',
                        route : {name : 'Home'}
                    },
                    {
                        name : 'سوالات متداول',
                        desc : 'سوالات پرتکرار کاربران سایت',
                        icon : 'faq.svg',
                        route : {name : 'FAQ'}
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
#home-contact-us{
    padding: 50px 0;
    .contact-us{
        width: 100%;
        max-width: 1440px;
        padding: 25px;
        h3{
            text-align: right;
        }
        .items{
            .item{
                img{
                    background-image: url("../../assets/Icons/contact-normal.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 150px;
                    padding: 40px;
                }
                &:hover{
                    img{
                        background-image: url("../../assets/Icons/contact-hover.svg");
                    }
                }
            }
        }
    }
}
</style>