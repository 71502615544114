<template>
    <div id="home-benefit" class="d-flex-r-w-jsb-ac align-center">
        <pre class="basis-200">
            مزایای خرید و فروش
            در صرافی زرکوین
        </pre>
        <div class="benefits grow-1 basis-300" @mouseenter="hover=true;hovered=5;" @mouseleave="hover=false;animation();">
            <div v-for="n in 4" :key="n" class="benefit-item" :style="{'--position' : (n-1)*10 + '%'}"
                 :class="{'hovered' : n===hovered}">
                <div class="benefit-img">
                    <img width="222" :src="require('@/assets/benfit/'+headers[4-n].img+'.png')" alt=""/>
                    <inline-svg width="222" :src="require('@/assets/benfit/shadow.svg')"/>
                    <h3>{{headers[4-n].header}}</h3>
                    <p>
                        متنوع ترین بازار تبادل رمزارزها اعم از بیت کوین،اتریوم، تتر و ... در داخل کشور که به طور
                        روزافزون بر
                        تعداد آن ها اضافه خواهد شد
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HomeBenefit",
        data() {
            return {
                hovered: 5,
                hover : false,
                headers : [
                    {
                        img : 'Security',
                        header : 'امنیت خرید و فروش'
                    },
                    {
                        img : 'LowFee',
                        header : 'کارمزد پایین معاملات'
                    },
                    {
                        img : 'Support',
                        header : 'پشتیبانی 24 ساعته'
                    },
                    {
                        img : 'Variety',
                        header : 'تنوع کوین ها'
                    },
                ]
            }
        },
        methods: {
            async animation() {
                if (window.screen.width < 768 && !this.hover) {
                    this.hovered = this.hovered > 1 ? this.hovered - 1 : 4
                    await this.$sleep(2000)
                    this.animation()
                } else {
                    this.hovered = 5
                }
            }
        },
        mounted() {
            this.animation()
        }
    }
</script>

<style lang="scss">
    #home-benefit {
        width: 100%;
        max-width: 1440px;
        padding: clamp(10px, 1.7vw, 25px);
        background-image: url("../../assets/benefit-back.svg");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: auto 90%;
        min-height: 400px;

        pre {
            text-align: right;
        }

        .benefits {
            position: relative;
            height: 222px;

            .benefit-item {
                --position: 0;
                --speed: 0.5s;
                position: absolute;
                color: var(--dark);
                left: var(--position);
                top: 50%;
                transform: translateY(-50%);
                transition: all var(--speed);

                &:before {
                    content: '';
                    background-color: transparent;
                    width: 222px;
                    height: 230px;
                    display: block;
                    transition: all var(--speed);
                }

                .benefit-img {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);

                    svg {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        opacity: 0;
                        transition: opacity var(--speed);
                    }

                    h3 {
                        position: absolute;
                        text-align: right;
                        right: 150px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 55px;
                        font-size: var(--m-font);
                        font-weight: bold;
                        transition: right var(--speed);
                    }

                    p {
                        position: absolute;
                        left: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 62%;
                        text-align: right;
                        font-size: var(--m-font);
                        opacity: 0;
                        transition: opacity var(--speed);
                    }
                }

                &:hover, &.hovered {
                    &:before {
                        width: 500px;
                    }

                    ~ .benefit-item {
                        &:before {
                            width: 500px;
                        }

                        &:nth-of-type(1) {
                            z-index: 4;
                        }

                        &:nth-of-type(2) {
                            z-index: 3;
                        }

                        &:nth-of-type(3) {
                            z-index: 2;
                        }

                        &:nth-of-type(4) {
                            z-index: 1;
                        }
                    }

                    z-index: 5;

                    .benefit-img {
                        p {
                            opacity: 1;
                        }

                        svg {
                            opacity: 1;
                        }

                        h3 {
                            right: 15px;
                        }
                    }
                }

                &.hovered {
                    &:before {
                        width: 222px !important;
                    }

                    ~ .benefit-item {
                        &:before {
                            width: 222px;
                        }
                    }
                }
            }
        }
    }
</style>