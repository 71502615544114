<template>
    <table id="market-chart" class="nd-font">
        <tr>
            <th>
                <div class="d-flex-ac-jfs-gap25">
                    ارز پایه
                    <span class="tabs d-flex-ac gradient-template-linear-sidebar">
                                <img @click="toman=true" :class="{ 'currentTab' : toman}" width="40"
                                     src="@/assets/Icons/coins/TOMAN.png" alt="TOMAN"/>
                                <img @click="toman=false" :class="{ 'currentTab' : !toman}" height="38" width="38"
                                     src="@/assets/Icons/coins/TETHER.png" alt="TOMAN"/>
                            </span>
                </div>
            </th>
            <th><span>رمز ارز</span></th>
            <th>آخرین قیمت</th>
            <th>تغییرات 24 ساعت</th>
            <th class="mobile-hidden">نمودار هفتگی</th>
            <th class="mobile-hidden"></th>
        </tr>
        <tr v-for="item in items" :key="item.name">
            <td>
                        <span class="d-flex-ac-jfs-gap15 pr-25">
                            <img width="40" :src="require('@/assets/Icons/coins/'+item.relatedCoin+'.png')"
                                 :alt="item.relatedCoin">
                            ({{$coinUnit[item.relatedCoin]}})
                        </span>
            </td>
            <td>
                {{$coinLabel[item.relatedCoin]}}
            </td>
            <td>{{item.price}}</td>
            <td dir="ltr" class="selected" :style="{color : item.change >= 0 ? 'var(--buy)' : 'var(--sell'}">
                {{item.change >= 0 ? '+ ' : '- ' }}{{Math.abs(item.change)}}%
            </td>
            <td class="mobile-hidden">
                <inline-svg height="45" :src="require('@/assets/trade-line.svg')"/>
            </td>
            <td class="mobile-hidden">
                <a class="market-btn"
                   :href="$href({name : 'Market',params:{tradeTo: toman ? 'TOMAN' : 'TETHER',tradeFrom:'BITCOIN'}})"
                   @click.prevent="$push({name : 'Market',params:{tradeTo: toman ? 'TOMAN' : 'TETHER',tradeFrom:'BITCOIN'}})">معامله
                    در بازار</a>
            </td>
        </tr>
    </table>
</template>

<script>
    export default {
        name: "MarketsChart",
        props: ['items', 'toman'],
    }
</script>

<style lang="scss">
    #market-chart {
        border-collapse: collapse;
        width: 100%;

        tr {
            &:first-of-type {
                height: 50px;
            }

            &:nth-of-type(n+2) {
                height: 80px;
            }

            th {
                &:first-of-type {
                    max-width: 170px;
                }

                &:nth-of-type(n+2) {
                    background-color: var(--dark-gray);
                }

                &:nth-of-type(2) {
                    border-top-right-radius: 65px;
                    border-bottom-right-radius: 65px;
                    padding-right: 25px;
                    text-align: right;
                }

                &:last-of-type {
                    border-top-left-radius: 65px;
                    border-bottom-left-radius: 65px;
                }

                @media (max-width: 768px) {
                    &:nth-of-type(4) {
                        border-top-left-radius: 65px;
                        border-bottom-left-radius: 65px;
                    }
                }
            }

            td {
                &:first-of-type {
                    text-align: right;
                    text-align: -webkit-right;
                }

                &:nth-of-type(n+2) {

                }

                &:nth-of-type(2) {
                    padding-right: 25px;
                    text-align: right;
                }

                &:last-of-type {

                }

                .market-btn {
                    background-color: var(--yellow2);
                    padding: 5px 15px;
                    border-radius: var(--s-radius);
                    color: #fff;
                    font-weight: 500;
                    font-size: var(--n-font);
                }
            }

            .tabs {
                position: relative;

                &:before {
                    content: '';
                    background-color: var(--yellow2);
                    background-image: var(--linear-template);
                    position: absolute;
                    width: 52px;
                    height: 52px;
                    right: 25%;
                    top: 50%;
                    transform: translate(50%, -50%);
                    border-radius: 50%;
                }

                img {
                    order: 1;
                    cursor: pointer;

                    &.currentTab {
                        transform: scale(calc(50 / 40));
                        order: 0;
                        cursor: default;
                    }
                }
            }

            .mobile-hidden {
                @media (max-width: 768px) {
                    display: none;
                }
            }
        }


    }
</style>