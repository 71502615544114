<template>
    <div id="home-header" class="d-flex-r-w-ast-jc-g1-s0">
        <div class="input basis-200 d-flex-c-jc-afe">
            <form class="d-flex-c-jc-afs-gap15 p-40" @submit.prevent="$push({name : 'Register'})">
                <p class="fullWidth">
                    برای پیوستن به بازار ثبت نام کنید
                </p>
                <label class="d-flex-r-jsb-ast">
                    <input class="grow-1" type="text" id="email" placeholder="ورود ایمیل">
                    <button class="gradient-template-linear-pink back" type="submit">شروع معامله</button>
                </label>
            </form>
        </div>
        <div class="header basis-500 d-flex-c-ac-jc-gap15 grow-0 shrink-1 p-40">
            <h1>
                خرید و فروش ارزهای دیجیتال
            </h1>
            <p>
                امن‌ترین مکان برای خرید، فروش و مدیریت رمزارز های شما
            </p>
        </div>
        <div class="img basis-200">
            <img src="@/assets/home-header.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "HomeHeader"
    }
</script>

<style lang="scss">
    #home-header {
        min-height: 430px;

        .input {
            background: linear-gradient(180deg, #3F4347 0%, #424649 100%);
            text-align: right;
            order: 2;

            label {
                background-color: var(--white);
                height: 45px;
                border-radius: var(--s-radius);
                overflow: hidden;
                input{
                    padding: 0 10px;
                }
                button{
                    width: 100px;
                    color: var(--white);
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }

        .header {
            min-height: 400px;
            order: 3;
            @media (max-width: 1035px)  {
                background-image: linear-gradient(90deg,#00000050 0%,#3F434700 100%),url("../../assets/home-header.png");
                background-size: cover,50%;
                background-repeat: no-repeat;
                background-position: left,left 30px bottom 15px;
                background-blend-mode: normal;
                flex-grow: 1;
                order: 1;
                padding: 0 10px;
            }
        }

        .img{
            position: relative;
            order: 4;
            img{
                position: absolute;
                width: 100%;
                max-width: 500px;
                transform: scale(1.2);
                bottom: 0;
                right: 0;
                /*left: 0;*/
            }

            @media (max-width: 1035px)  {
                display: none;
            }
        }
    }
</style>