<template>
    <div id="home-chart" class="d-flex-r-ac-jc">
        <div class="chart nd-font">
            <markets-chart :items="items" :toman="toman"/>
            <div class="footer d-flex-r-jfs-ac px-15">
                <a class="gradient-template-gold-linear grow-0 text-gradient d-flex-r-ac-jfs-gap10"
                   :href="$href({name : 'Markets'})" @click.prevent="$push({name : 'Markets'})">
                    همه بازار ها
                    <inline-svg height="10" :src="require('@/assets/Icons/arrow-left.svg')"/>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import MarketsChart from "@/components/Tools/MarketsChart";
    export default {
        name: "HomeChart",
        components: {MarketsChart},
        data() {
            return {
                toman: true,
                items: [
                    {
                        relatedCoin: 'BITCOIN',
                        price: '1,245,145',
                        change: -2.55,
                    },
                    {
                        relatedCoin: 'DOGE_COIN',
                        price: '1,451',
                        change: 2.55,
                    },
                    {
                        relatedCoin: 'ETHEREUM',
                        price: '45,415',
                        change: -2.55,
                    },
                    {
                        relatedCoin: 'TETHER',
                        price: '20,145',
                        change: 2.55,
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
    #home-chart {
        background-color: #fff;
        padding: 150px 15px 100px;

        .chart {
            background-color: var(--light-gray);
            border-radius: var(--l-radius);
            color: var(--dark);
            padding: clamp(10px, 1.7vw, 25px);
            width: 100%;
            max-width: 1330px;
            overflow: hidden;
            box-shadow: 2px 11px 10px #CACACA96;

            .footer {
                background-color: var(--dark-gray);
                margin: clamp(-25px, -1.7vw, -10px);
                margin-top: 0;
                height : 65px;
                svg{
                    fill: var(--dark);
                }
            }
        }
    }
</style>