<template>
    <div id="home" class="d-flex-c-ast-jsb">
        <home-header/>
        <home-chart/>
        <home-benefit/>
        <home-blog/>
        <home-contact-us/>
    </div>
</template>

<script>
    import HomeHeader from "@/components/Home/HomeHeader";
    import HomeChart from "@/components/Home/HomeChart";
    import HomeBenefit from "@/components/Home/HomeBenefit";
    import HomeBlog from "@/components/Home/HomeBlog";
    import HomeContactUs from "@/components/Home/HomeContactUs";
    export default {
        name: "Home",
        components: {HomeContactUs, HomeBlog, HomeBenefit, HomeChart, HomeHeader}
    }
</script>

<style scoped>

</style>