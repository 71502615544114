<template>
    <div id="home-blog" class="d-flex-ac-jc">
        <div class="blog d-flex-r-w-ast-jsb">
            <div class="category d-flex-c-afs-jsa grow-1 basis-400">
                <h3>یادگیری معاملات رمز ارز زرکوین</h3>
                <div class="tags d-flex-r-w-gap10 gradient-template-linear-pink">
                    <span class="fullWidth">دسته بندی های بلاگ</span>
                    <a>اخبار</a>
                    <a>مفاهیم پایه</a>
                    <a>تحلیل و سرمایه گذاری</a>
                    <a>مقالات</a>
                </div>
            </div>
            <div class="gallery d-flex-c-ast-jsb grow-4 basis-400">
                <div class="header d-flex-r-ac-jsb py-10">
                    <h4>آخرین مطالب</h4>
                    <a class="gradient-template-gold-nav-bar text-gradient">مطالب بیشتر</a>
                </div>
                <div class="box-row d-flex-r-w-ast-g1-jsb-gap15">
                    <div class="box basis-200 gradient-template-blog back l-radius" v-for="n in 3" :key="n">
                        <img width="100%" src="../../assets/blog.png" alt="">
                        <p class="m-font">آشنایی با ۱۱ اصطلاح ضروری در بازارهای مالی </p>
                        <div class="footer d-flex-r-ac-jsb px-10">
                            <div class="time d-flex-r-ac-jfs-gap10">
                                <inline-svg width="23" :src="require('@/assets/Icons/clock.svg')"/>
                                '6
                            </div>
                            <p>
                                اردیبهشت ۰۰
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HomeBlog"
    }
</script>

<style lang="scss">
    #home-blog {
        background-color: #3f4347;

        .blog {
            width: 100%;
            max-width: 1440px;

            .category {

                background-image: linear-gradient(180deg, var(--dark), #434648);
                padding: 25px;
                text-align: right;

                .tags {
                    a {
                        background: var(--linear-template);
                        border-radius: 6px;
                        padding: 3px 10px;
                    }
                }
            }
        }

        .gallery{
            padding: 25px;
            .header{

            }
            .box-row{
                .box{
                    text-align: right;
                    overflow: hidden;
                    > p{
                        padding: 10px;
                    }
                    > img{
                        object-fit: cover;
                    }
                }
                .footer{

                }
            }
        }
    }
</style>